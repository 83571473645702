import React, {useState} from 'react';
import './Recipes.css'
import {EditRecipe} from "./EditRecipe";
import {sorter} from "../utils";
import {SortableList} from "../General/SortableList";
import {firebaseSet} from "../FireBase/firebase";


const save = (recipes) => {
    firebaseSet(['recipes'], recipes);
}

export const EditRecipes = (props) => {
    const [adding, setAdding] = useState(false);
    const [editing, setEditing] = useState(null);

    const renderEditRecipe = (recipe) => (
        <>
            <EditRecipe
                index={props.recipes && Object.keys(props.recipes).length}
                recipe={recipe}
                ingredients={props.ingredients}
                close={() => {
                    recipe ? setEditing(null) : setAdding(false)
                }}
            />
        </>
    )

    const renderRecipe = (rec) => {
        return (
            <button
                key={rec.id}
                className={'button listable recipes__recipe'}
                onClick={() => setEditing(rec.id)}
            >
                {rec.name}
            </button>
        )
    }

    const content = adding ? renderEditRecipe() :
        editing ? renderEditRecipe(props.recipes[editing]) :
            (
                <>
                    <h1 className={'header mainHeader textCenter'}>Rediger oppskrifter</h1>
                    <div className="recipes__content flexcenter scrollSafe">
                        <SortableList
                            items={props.recipes}
                            setItems={save}
                            renderItem={renderRecipe}
                            draggableClass={'recipes__recipeWrapper flexcenter'}
                        />
                        <button
                            className={'button roundButton'}
                            onClick={() => setAdding(true)}
                        >+
                        </button>
                    </div>
                </>
            )

    return (
        <section className="page">
            {content}
        </section>
    );
}