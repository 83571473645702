import React, {useEffect, useState} from 'react';
import './RecipeStep.css';
import {Input} from "../../General/Input";
import {format2digitTimeString, generateUuidv4} from "../../utils";
import {useHistory} from "react-router-dom";
import {IngredientSelect} from "../../Ingredients/EditableIngredients/IngredientSelect";
import {CancelConfirm} from "../../General/CancelConfirm";
import {TimerSelect} from "../../General/TimerSelect/TimerSelect";

let textRef, tipRef;
export const EditStep = (props) => {
    const [step, setStep] = useState(props.step || {
        id: generateUuidv4(),
        name: '',
        text: '',
        tip: ''
    })
    const [editTimer, setEditTimer] = useState(false);

    useEffect(() => {
        if (textRef && props.step) {
            textRef.innerText = props.step.text || '';
            tipRef.innerText = props.step.tip || '';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setIngredients = ingredients =>
        setStep(old => ({
            ...old,
            ingredients
        }))
    const history = useHistory();

    const renderInput = (key, header, placeholder, type) => (
        <Input
            className={header && 'editableHeader'}
            value={step[key]}
            placeholder={placeholder}
            type={type}
            onChange={value => {
                if (value.length > 42) return;
                setStep(prev => ({
                    ...prev,
                    [key]: value
                }))
            }}
            onEscape={props.onCancel}
        />
    )

    const close = () => {
        history.replace({state: {addStep: undefined, editStep: undefined}})
    }

    const submit = () => {
        props.add(step);
        close();
    }

    const renderButtons = () => {
        let onCancel, onNeutral, cancelText, neutralText;
        if (props.step) {
            onNeutral = close;
            neutralText = 'Avbryt';
            cancelText = 'Slett';
            onCancel = () => {
                props.remove(step.id);
                close();
            }
        } else {
            onNeutral = close;
            neutralText = 'Forkast';
        }
        return (
            <CancelConfirm
                onCancel={onCancel}
                onConfirm={submit}
                onNeutral={onNeutral}
                confirmText={'Lagre'}
                cancelText={cancelText}
                neutralText={neutralText}
            />
        )
    }

    const renderTimer = () => {
        return (
            <>
                {
                    editTimer && (<TimerSelect
                            time={step.time}
                            close={(time) => {
                                setEditTimer(false)
                                setStep(prevValue => ({
                                    ...prevValue,
                                    time
                                }))
                            }}
                        />
                    )
                }
                <button
                    className={'editTimerButton button'}
                    onClick={() => setEditTimer(true)}
                >
                    {step.time ? `Timer: ${step.time[0] ? `${step.time[0]}:` : ''}${format2digitTimeString(step.time[1])}:${format2digitTimeString(step.time[2])}` : 'Legg til timer'}
                </button>
            </>
        )
    }

return (
    <>
        <div className="editStep scrollSafe">
            <h1 className="header textCenter">Steg {props.index + 1}</h1>
            {renderInput(
                'name',
                true,
                'Nytt steg'
            )}

            <h2 className="header textCenter">Tekst:</h2>
            <span
                className="textarea"
                role="textbox"
                contentEditable={true}
                defaultValue={step.text}
                ref={node => textRef = node}
                onInput={e => {
                    const text = e.target.innerText;
                    setStep(old => ({
                        ...old,
                        text
                    }))
                }}
            />
            {renderTimer()}

            <h2 className="header textCenter">Tips:</h2>
            <span
                className="textarea"
                role="textbox"
                contentEditable={true}
                defaultValue={step.tip}
                ref={node => tipRef = node}
                onInput={e => {
                    const tip = e.target.innerText;
                    setStep(old => ({
                        ...old,
                        tip
                    }))
                }}
            />
            <IngredientSelect
                added={step.ingredients || {}}
                step={props.step}
                steps={props.steps}
                ingredientPool={props.recipeIngredients || {}}
                ingredients={props.ingredients || {}}
                setIngredients={setIngredients}
                add={value =>
                    setIngredients({
                        ...step.ingredients,
                            [value.id]: value
                        })
                    }
                    remove={id => {
                        const ingredients = {...step.ingredients}
                        delete ingredients[id];
                        setIngredients(ingredients);
                    }}
                />
            {renderButtons()}
            </div>
        </>
    );
}