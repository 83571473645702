import React, {useState} from "react";
import './Ingredients.css';
import {EditIngredient} from "./EditIngredient";
import {firebaseSet} from "../FireBase/firebase";
import {getSorter} from "../utils";

const save = ingredient => firebaseSet(['ingredients', ingredient.id], ingredient);

export const Ingredients = (props) => {
    const [adding, setAdding] = useState(false);
    const [editing, setEditing] = useState(-1);

    const renderIngredient = (ingredient, i) => {
        if (editing === i) {
            const close = () => setEditing(-1);
            return (
                <EditIngredient
                    key={ingredient.id}
                    header={'Rediger ingrediens'}
                    ingredient={ingredient}
                    onSubmit={value => {
                        save(value);
                        close();
                    }}
                    onCancel={close}
                />
            )
        }
        return (
            <button
                className={'button listable ingredients__ingredient'}
                key={ingredient.id}
                onClick={() => setEditing(i)}
            >
                {ingredient.name}
            </button>
        )
    }

    return (
        <section className="page">
            <h1 className={'header mainHeader textCenter'}>Rediger ingredienser</h1>
            <div className="ingredients__content scrollSafe flexcenter">
                <div className="flexRowWrap">
                    {
                        props.ingredients && Object.values(props.ingredients)
                            .sort(getSorter('name'))
                            .map(renderIngredient)
                    }
                </div>
                {
                    adding ? (
                        <EditIngredient
                            header={'Ny ingrediens'}
                            onSubmit={value => {
                                save(value);
                                setAdding(false);
                            }}
                            onCancel={() => setAdding(false)}
                            fullScreen
                        />
                    ) : (
                        <button
                            className={'button roundButton'}
                            onClick={() => setAdding(true)}
                        >+
                        </button>
                    )
                }
            </div>
        </section>
    );
}