import React from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {generateUuidv4, sorter} from "../utils";

import {ReactComponent as SortIcon} from '../resources/icons/sort.svg';

export const SortableList = ({items, setItems, renderItem, draggableClass}) => {
    if (!items) return <></>;
    let list = items && Object.values(items).sort(sorter);

    return (
        <DragDropContext onDragEnd={result => {
            const {destination, source} = result;
            if (!destination) return;
            if (destination.index === source.index) return;
            let newList = [...list];

            newList.splice(source.index, 1);
            newList.splice(destination.index, 0, list[source.index])
            const newItems = newList.reduce((acc, e, index) => (
                    {
                        ...acc,
                        [e.id]: {
                            ...e,
                            index
                        }
                    }),
                {});
            setItems(newItems)
        }}>
            <div className={'sortList'}>
                <Droppable droppableId={generateUuidv4()}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="items"
                        >
                            {list.map((item, i) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={i}
                                    >
                                        {
                                            (provided, snapshot) => {
                                                const draggingClass = snapshot.isDragging ? ' dragging' : '';
                                                return (
                                                    <div
                                                        className={`draggable ${draggableClass}${draggingClass}`}
                                                        {...provided.draggableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <div className="sortList__handle__wrapper">
                                                            <div
                                                                className="sortList__handle roundButton popOut small"
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <SortIcon/>
                                                            </div>
                                                        </div>
                                                        {renderItem(item, i, draggingClass)}
                                                    </div>
                                                )
                                            }
                                        }
                                    </Draggable>
                                )
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </DragDropContext>
    );
}
