import React, {useEffect, useState} from 'react';
import {generateUuidv4} from "../utils";
import {Input} from "../General/Input";
import {Link, useLocation} from "react-router-dom";
import {EditStep} from "./RecipeSteps/EditStep";
import {IngredientSelect} from "../Ingredients/EditableIngredients/IngredientSelect";
import {EditableIngredientList} from "../Ingredients/EditableIngredients/EditableIngredientList";
import {firebaseSet} from "../FireBase/firebase";
import {SortableList} from "../General/SortableList";

export const EditRecipe = (props) => {
    const [recipe, setRecipe] = useState(props.recipe ? {...props.recipe} : {
            id: generateUuidv4(),
            name: '',
            ingredients: {},
            steps: {},
            index: props.index
        }
    );

    useEffect(() => props.recipe && setRecipe(props.recipe),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    const save = (newRecipe) => {
        firebaseSet(['recipes', recipe.id], newRecipe || recipe);
    }

    const setIngredients = ingredients => {
        const newRecipe = {
            ...recipe,
            ingredients
        };
        save(newRecipe);
        setRecipe(newRecipe);
    }

    const setSteps = steps => {
        const newRecipe = {
            ...recipe,
            steps
        };
        save(newRecipe);
        setRecipe(newRecipe);
    }

    const renderIngredients = () => (
        <EditableIngredientList
            ingredientLibrary={props.ingredients}
            ingredients={recipe.ingredients}
            setIngredients={setIngredients}
        />
    )

    const renderStep = (step) => {
        console.log('step: ', step);
        return (
            <Link
                key={step.id}
                className="button listable popOut textCenter"
                to={{state: {editStep: step.id}}}
            >
                {step.name || step.index + 1}
            </Link>
        )
    }

    const renderSteps = () => {
        const steps = recipe.steps && Object.values(recipe.steps);
        return (
            <>
                <h4 className="header textCenter noMarginTop">Steg:</h4>
                {steps && (
                    <SortableList
                        items={steps}
                        setItems={setSteps}
                        renderItem={renderStep}
                        draggableClass={'step'}
                    />
                )}
                <br/>
                <Link
                    className={'button roundButton'}
                    to={{state: {addStep: true}}}
                >
                    +
                </Link>
            </>
        )
    }

    const state = useLocation().state || {};
    const {addIngredients, addStep, editStep} = state;
    const editingStep = editStep !== undefined && recipe.steps && recipe.steps[editStep];

    return addIngredients ? (
        <>
            <Link
                className="button backButton"
                to={{state: {addIngredients: false}}}
            >
                {'<'}
            </Link>
            <IngredientSelect
                added={recipe.ingredients}
                index={recipe.ingredients ? Object.keys(recipe.ingredients).length : 0}
                ingredients={props.ingredients}
                setIngredients={setIngredients}
                add={value =>
                    setIngredients({
                        ...recipe.ingredients,
                        [value.id]: value
                    })
                }
                remove={id => {
                    const ingredients = {...recipe.ingredients}
                    delete ingredients[id];
                    setIngredients(ingredients);
                }}
            />
        </>
    ) : (addStep || editingStep) ? (
        <>
            <EditStep
                step={editingStep}
                steps={recipe.steps}
                index={
                    editingStep ?  editingStep.index :
                    recipe.steps ? Object.keys(recipe.steps).length : 0
                }
                recipeIngredients={recipe.ingredients}
                ingredients={props.ingredients}
                add={value =>
                    setSteps({
                        ...recipe.steps,
                        [value.id]: value
                    })
                }
                remove={id => {
                    const steps = {...recipe.steps}
                    delete steps[id];
                    setSteps(steps);
                }}
            />
        </>
    ) : (
        <section className="editRecipe">
            <div className="scrollSafe flexcenter editRecipe__content">
                <button className="button backButton" onClick={props.close}>{'x'}</button>
                <Input
                    className={'editableHeader'}
                    value={recipe['name']}
                    placeholder={'Ny oppskrift'}
                    onChange={value => {
                        if (value.length > 42) return;
                        setRecipe(prev => ({
                            ...prev,
                            name: value
                        }))
                    }}
                />
                {renderIngredients()}
                <br/>
                <br/>
                {renderSteps()}
                <br/>
                <br/>
                <button
                    className="button confirmButton"
                    onClick={() => {
                        save()
                        props.close()
                    }}
                >
                    Lagre
                </button>
            </div>
        </section>
    );
}