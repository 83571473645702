import React from 'react';
import {generateUuidv4} from "../utils";

export const Input = (props) => {
    const id = generateUuidv4();
    return (
        <>
            {props.label && (<label
                    className={'input__label'}
                    htmlFor={id}
                >
                    {props.label}
                </label>
            )}
            <input
                id={id}
                className={props.className}
                autoFocus={props.autoFocus}
                type={props.type || 'text'}
                value={props.value}
                placeholder={props.placeholder}
                onChange={e => props.onChange(e.target.value)}
                onKeyDown={e => {
                    if (e.code === "Enter") {
                        props.onEnter && props.onEnter();
                    }
                    if (e.code === "Escape") {
                        props.onEscape && props.onEscape();
                    }
                }}
            />
        </>
    );
}