import React from 'react';
import './Recipe.css';
import {Link, useLocation} from "react-router-dom";
import {FourOhFour} from "../General/FourOhFour";
import {sorter} from "../utils";

export const Recipe = (props) => {
    const recipe = props.recipes[useLocation().pathname.split('/')[1]];
    if (!recipe) return <FourOhFour/>

    const renderIngredient = (value) => {
        const ingredient = props.ingredients[value.id];
        return (
            <p
                className="reservation__ingredient popOut"
                key={value.id}
            >
                {`${value.quantity} ${ingredient.unit} ${ingredient.name}`}
            </p>
        )
    }

    const renderStep = (value, i) => {
        return (
            <div
                className="reservation__step popOut"
                key={value.id}
            >
                <h2 className={'header textCenter'}>{i + 1}: {value.name}</h2>
                {
                    value.ingredients && (
                        <div className="reservation__step__ingredients flexRowWrap justifyStart">
                            {Object.values(value.ingredients).map(renderIngredient)}
                        </div>
                    )
                }
                <br/>
                {renderTextBlock(value.text)}
                {value.tip && value.tip.length > 0 && (
                    <>
                        <h3 className={'header textCenter'}>Tips:</h3>
                        {renderTextBlock(value.tip)}
                    </>
                )}
            </div>
        )
    }

    const renderTextBlock = (text) =>
        text.split(/\r?\n/).map((t, i) => (<p key={i}>{t}</p>))

    return (
        <div className="page recipe">
            <Link
                className="button backButton"
                to={'/'}
            >
                {'<'}
            </Link>
            <h1 className={'header textCenter'}>{recipe.name}</h1>
            <div className="reservation__ingredients">
                <h3 className={'header textCenter'}>Ingredienser</h3>
                {
                    recipe.ingredients ?
                        <div className="flexRowWrap justifyStart">
                            {Object.values(recipe.ingredients).sort(sorter).map(renderIngredient) }
                        </div>
                        :
                        <p>
                            Ingen ingredienser lagt til
                        </p>
                }
            </div>
            <div className="reservation__steps">
                <h3 className={'header textCenter'}>Steg</h3>
                {
                    recipe.steps ?
                        Object.values(recipe.steps).sort(sorter).map(renderStep) :
                        <p>
                            Ingen steg lagt til
                        </p>
                }
            </div>
        </div>
    );
}