import React from 'react';
import './EditableIngredient.css'
import {Input} from "../../General/Input";
import {ReactComponent as RemoveIcon} from '../../resources/icons/remove.svg';

export const EditableIngredient = (props) => {
    const limited = props.quantityLimit !== undefined && props.quantityLimit !== null;
    const quantityLimit = limited && parseFloat(props.quantityLimit);
    if (props.available !== -1) {

    }
    const quantity = parseFloat(props.value.quantity);
    const max = props.available !== -1 && quantity >= props.available;

    const renderRemoveButton = () => {
        if (props.simple || !props.remove) return <></>;
        return (
            <div className="removeButtonContainer">
                <button
                    className="button clearButton roundButton small removeButton"
                    onClick={props.remove}
                >
                    <RemoveIcon/>
                </button>
            </div>
        )
    }

    if (!props.editing) {
        const text = props.simple ? props.ingredient.name :
            `${props.value.quantity} ${props.ingredient.unit} ${props.ingredient.name}`
        return (
            <div
                className={`editableIngredient flexcenter popOut`}
                onClick={() => props.simple || props.onClick(true)}
            >
                {text}
                {renderRemoveButton()}
            </div>
        )
    }

    const addQuantity = (negative) => {
        let amount = parseFloat(props.ingredient.baseQuantity);
        if (negative) amount = -amount;
        let result = parseFloat(props.value.quantity) + amount;
        if (result < 0) result = 0;
        if (limited && result > quantityLimit) result = quantityLimit;
        props.setQuantity(result);
    }

    const min = parseFloat(props.value.quantity) < 1;
    return (
        <>
            <div
                className="touchOutsideSensor"
                onClick={() => props.onClick(false)}
            />
            <div className="editableIngredient editable popOut">
                {renderRemoveButton()}
                <div>
                    <button
                        className={`button popOut editableIngredient__quantityButton${max ? ' disabled' : ''}`}
                        onClick={() => max || addQuantity()}
                    >
                        + {props.ingredient.baseQuantity}
                    </button>
                </div>
                <div className="editableIngredient__value">
                <span>
                    {props.ingredient.name}{': '}
                </span>
                    <div
                        className="editableIngredient__inputWrapper"
                    >
                        <Input
                            value={props.value.quantity}
                            onChange={props.setQuantity}
                            type={'number'}
                        />
                    </div>
                    <span>
                    {' '}{quantityLimit !== false ? ` / ${quantityLimit} ` : ''}{props.ingredient.unit}
                </span>
                </div>
                <div>
                    <button
                        className={`button popOut editableIngredient__quantityButton${min ? ' disabled' : ''}`}
                        onClick={() => min || addQuantity(true)}
                    >
                        - {props.ingredient.baseQuantity}
                    </button>
                </div>
            </div>
        </>
    );
}