import './App.css';
import './General/General.css';
import React, {useEffect, useState} from "react";
import {Ingredients} from "./Ingredients/Ingredients";
import {listen} from "./FireBase/firebase";
import {EditRecipes} from "./EditRecipes/EditRecipes";
import {Menu} from "./General/Menu/Menu";

import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import {Recipes} from "./Recipes/Recipes";
import {Recipe} from "./Recipes/Recipe";
import {FourOhFour} from "./General/FourOhFour";

const getColorScheme = () => {
    const localScheme = localStorage.getItem('colorScheme');
    if (localScheme === undefined)
        return '';
    return localScheme;
}

function App() {
    const [ingredients, setIngredients] = useState({});
    const [recipes, setRecipes] = useState({});
    const [colorScheme, setColorScheme] = useState(getColorScheme())

    useEffect(() => {
        listen('ingredients', setIngredients)
        listen('recipes', setRecipes)
    }, [])

    const renderMenu = () => (
        <Menu
            setColorScheme={scheme => {
                localStorage.setItem('colorScheme', scheme)
                setColorScheme(scheme);
            }}
        >
            <Link
                className={'button navButton'}
                to={'/'}
            >
                Oppskrifter
            </Link>
            <br/>
            <br/>
            <Link
                className={'button navButton'}
                to={'/edit-recipes'}
            >
                Rediger oppskrifter
            </Link>
            <Link
                className={'button navButton'}
                to={'/ingredients'}
            >
                Rediger ingredienser
            </Link>
        </Menu>
    )

    return (
        <BrowserRouter>
            <div className={`App ${colorScheme}`}>
            <div className={`app__content`}>
                {renderMenu()}
                <Switch>
                    <Route
                        path="/edit-recipes"
                        render={() => (
                            <EditRecipes
                                recipes={recipes}
                                ingredients={ingredients}
                            />
                        )}
                    />
                    <Route
                        path="/ingredients"
                        render={() => (<Ingredients ingredients={ingredients}/>)}
                    />
                    <Route
                        exact
                        path="/:id"
                        render={() => (<Recipe
                            recipes={recipes}
                            ingredients={ingredients}
                        />)}
                    />
                    <Route
                        exact
                        path="/"
                        render={() => (<Recipes recipes={recipes}/>)}
                    />
                    <Route
                        path="/"
                        render={() => (<FourOhFour/>)}
                    />
                </Switch>
            </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
