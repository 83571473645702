import React, {useState} from 'react';
import './TimerSelect.css';
import {format2digitTimeString} from "../../utils";

export const TimerSelect = (props) => {
    const [hours, setHours] = useState(props.time ? props.time[0] : 0);
    const [minutes, setMinutes] = useState(props.time ? props.time[1] : 0);
    const [seconds, setSeconds] = useState(props.time ? props.time[2] : 0);
    const [y, setY] = useState();
    const [initialValue, setInitialValue] = useState();

    const renderSetter = (key) => {
        let value, setter;
        if (key === 'seconds') {
            value = seconds;
            setter = setSeconds;
        }
        if (key === 'minutes') {
            value = minutes;
            setter = setMinutes;
        }
        if (key === 'hours') {
            value = hours;
            setter = setHours;
        }
        if (value === null || setter === null) return <></>;

        let label = key !== 'hours' ? format2digitTimeString(value) : value;

        return (
            <div
                className="timerSelect__setter popOut"
                key={key}
                onTouchStart={e => {
                    setY(e.touches[0].clientY)
                    setInitialValue(value)
                }}
                onTouchMove={(e) => {
                    const diff = Math.round((y - e.touches[0].clientY) / 10);
                    let newValue = initialValue + diff;
                    console.log(' $ $ $ $ $ ');
                    console.log('newValue: ', newValue);
                    if (key !== 'hours' && newValue > 59) {
                        newValue -= 60;
                    } else if (key !== 'hours' && newValue < 0) {
                        newValue += 60;
                    } else if (newValue < 0) {
                        newValue = 0;
                    }
                    if (newValue !== value)
                        setter(newValue);
                }}
            >
                {label}
            </div>
        )
    }

    console.log('props.time: ', props.time);

    return (
        <>
            <div
                className="touchOutsideSensor"
                onClick={() => props.close([hours, minutes, seconds])}
            />
            <div className="timerSelect popOut">
                <h1>Tid:</h1>
                <div className="timerSelect__setters">
                    {renderSetter('hours')}
                    <span>:</span>
                    {renderSetter('minutes')}
                    <span>:</span>
                    {renderSetter('seconds')}
                </div>
            </div>
        </>
    );
}