import React, {useState} from 'react';
import {Input} from "./Input";

export const SearchSelector = (props) => {
    const [value, setValue] = useState('');

    const renderOption = (option) => {
        return <button
            key={option.id}
            className={'button listable'}
            onClick={() => props.onSelect(option)}
        >
            {option.name}
        </button>
    }

    const exclude = props.exclude ? Object.keys(props.exclude) : [];
    const idPool = props.ingredientPool ? Object.keys(props.ingredientPool) : [];

    let result;
    let options = props.options.filter((option) =>
        !exclude.includes(option.id) &&
        (!props.ingredientPool || idPool.includes(option.id))
    );
    if (props.extraFilterFunc)
        options = options.filter(props.extraFilterFunc);

    if (value.length > 0) {
        result = options.filter((option) =>
            option.name.toLowerCase().includes(value.toLowerCase()))
    } else {
        result = options.slice(0, 3)
    }

    return (
        <>
            {
                props.header && (
                    <h5 className="header textCenter">{props.header}</h5>
                )
            }
            <div className="searchSelector popIn">
                {options.length > 3 && (
                    <Input
                        label={'Søk'}
                        value={value}
                        onChange={setValue}
                    />
                )}
                <div className="searchSelector__options flexcenter">
                    {result.map(renderOption)}
                    {value.length === 0 && options.length > 3 ? ('...') : ''}
                </div>
            </div>
        </>
    );
}