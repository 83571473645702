import React, {useEffect, useState} from 'react';
import {CancelConfirm} from "../General/CancelConfirm";
import {generateUuidv4} from "../utils";
import {Input} from "../General/Input";

export const EditIngredient = (props) => {
    const [ingredient, setIngredient] = useState({
        id: generateUuidv4(),
        name: '',
        unit: '',
        baseQuantity: 1
    });

    useEffect(() => props.ingredient && setIngredient(props.ingredient), [])

    const renderInput = (key, name, autoFocus, type) => (
        <>
            <Input
                label={name}
                autoFocus={autoFocus}
                type={type}
                value={ingredient[key]}
                onChange={value => {
                    if (value.length > 42) return;
                    setIngredient(prev => ({
                        ...prev,
                            [key]: value
                    }))
                }}
                onEnter={submit}
                onEscape={props.onCancel}
            />
        </>
    )

    const valid = ingredient.name.length !== 0;
    const submit = () => {
        valid && props.onSubmit(ingredient)
    }

    return (
        <div className={`editIngredient flexcenter${props.fullScreen ? ' fullScreen' : ' popOut'}`}>
            {
                props.header && (
                    <h3 className={'header textCenter'}>{props.header}</h3>
                )
            }
            <div className="editIngredient__fields">
                {renderInput(
                    'name',
                    'Navn',
                    true
                )}

                {renderInput(
                    'unit',
                    'Enhet'
                )}

                {renderInput(
                    'baseQuantity',
                    'Antall/klikk',
                    false,
                    'number',
                )}
            </div>
            <CancelConfirm
                invalid={!valid}
                onCancel={props.onCancel}
                onConfirm={submit}
            />
        </div>
    );
}