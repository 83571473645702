import React, {useState} from 'react';
import {SearchSelector} from "../../General/SearchSelector";
import {EditableIngredient} from "./EditableIngredient";
import {SortableList} from "../../General/SortableList";

export const IngredientSelect = (props) => {
    const [editing, setEditing] = useState();

    const getIngredientsUsed = (ingredientId) =>
        props.steps && Object.values(props.steps)
            .filter(step => step.id !== props.step.id && step.ingredients)
            .reduce((acc, step) =>
                    step.ingredients[ingredientId] ?
                        acc + parseFloat(step.ingredients[ingredientId].quantity) :
                        acc
                , 0)

    const getIngredientRemainingQuantity = (value) => {
        if (!props.ingredientPool ||
            !props.ingredientPool[value.id] ||
            props.ingredientPool[value.id].quantity === undefined) return 1;
        const totalQuantity = props.ingredientPool[value.id].quantity
        const used = getIngredientsUsed(value.id);
        return totalQuantity - used;
    }

    const renderIngredient = (value) => {
        const ingredient = props.ingredients[value.id];
        const editingThis = editing === value.id;
        return (
            <EditableIngredient
                key={value.id}
                value={value}
                ingredient={ingredient}
                available={getIngredientRemainingQuantity(value)}
                quantityLimit={props.ingredientPool && props.ingredientPool[value.id].quantity}
                editing={editingThis}
                onClick={(clickValue) => {
                    if (editingThis && !clickValue)
                        setEditing(undefined)
                    else
                        setEditing(value.id);
                }}
                remove={() => {
                    props.remove(value.id)
                    setEditing(undefined)
                }}
                setQuantity={quantity => {
                    props.add({
                        ...value,
                        quantity
                    });
                }}
            />
        )
    }

    return (
        <section className="editRecipeIngredients">
            <div className="editRecipeIngredients__added">
                <h1 className="header textCenter">Ingredienser</h1>
                {props.added && Object.keys(props.added).length > 0 ? (
                    <SortableList
                        items={props.added}
                        setItems={props.setIngredients}
                        renderItem={renderIngredient}
                        draggableClass={'recipes__recipeWrapper flexcenter'}
                    />
                ) : (
                    <p>Ingen ingredienser lagt til</p>
                    )
                }
            </div>
            <div className="editRecipeIngredients__add">
                <SearchSelector
                    header={'Legg til:'}
                    options={props.ingredients ? Object.values(props.ingredients) : []}
                    exclude={props.added}
                    extraFilterFunc={value => getIngredientRemainingQuantity(value) > 0}
                    ingredientPool={props.ingredientPool}
                    onSelect={value => {
                        const {id} = value;
                        const index = props.added ? Object.keys(props.added).length : 0;
                        props.add({
                            id,
                            quantity: 0,
                            index
                        })
                    }}
                />
            </div>
        </section>
    );
}