import React from 'react';

export const CancelConfirm = (props) => (
    <div className="cancelConfirm">
        {props.onCancel && (<button
            className="button cancelButton"
            onClick={props.onCancel}
        >
            {props.cancelText || 'Avbryt'}
        </button>)}
        {props.onNeutral && (<button
            className={'button'}
            onClick={props.onNeutral}
        >
            {props.neutralText || 'Meh'}
        </button>)}
        <button
            className={`button confirmButton${props.invalid ? ' disabled' : ''}`}
            onClick={() => props.onConfirm && !props.invalid && props.onConfirm()}
        >
            {props.confirmText || 'Kjør på'}
        </button>
    </div>
);