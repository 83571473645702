import React, {useState} from 'react';
import {EditableIngredient} from "./EditableIngredient";
import {Link} from "react-router-dom";

export const EditableIngredientList = (props) => {
    const [editingIngredient, setEditingIngredient] = useState();

    const ingredientsList = props.ingredients || {};

    const renderIngredient = (value) => {
        const ingredient = props.ingredientLibrary && props.ingredientLibrary[value.id];
        return (
            <EditableIngredient
                key={value.id}
                value={value}
                editing={editingIngredient === value.id}
                onClick={edit => setEditingIngredient(edit ? value.id : undefined)}
                ingredient={ingredient}
                setQuantity={quantity => {
                    const ingredients = {...ingredientsList}
                    ingredients[value.id] = {
                        ...ingredients[value.id],
                        quantity
                    };
                    props.setIngredients(ingredients);
                }}
                simple
            />
        )
    }

    return (
        <>
            <h4 className="header textCenter noMarginTop">Ingredienser:</h4>
            <div className="flexRowWrap">
                {Object.values(ingredientsList).map(renderIngredient)}
            </div>
            <br/>
            <Link
                className={'button roundButton'}
                to={{state: {addIngredients: true}}}
            >
                +{Object.keys(ingredientsList).length > 0 ? ' / -' : ''}
            </Link>
        </>
    );
}