export const generateUuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : r & (0x3 | 0x8);
        return v.toString(16);
    });
};

export const sorter = (a, b) =>
    a.index > b.index ? 1 :
    a.index < b.index ? -1 :
        0

export const getSorter = (key) => (a, b) =>
    a[key] > b[key] ? 1 :
    a[key] < b[key] ? -1 :
        0

export const format2digitTimeString = value => `${value < 10 ? '0' : ''}${value}`;