import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyASrDLBd_JAIrXckG7bJWDIWJVnsuuR8VA",
    authDomain: "re-c-p.firebaseapp.com",
    databaseURL: "https://re-c-p.firebaseio.com",
    projectId: "re-c-p",
    storageBucket: "re-c-p.appspot.com",
    messagingSenderId: "528689073381",
    appId: "1:528689073381:web:355c54b6e684c10b1b180c"
};

firebase.initializeApp(firebaseConfig);

const dbRef = firebase.database().ref();

export const listen = (key, callBack) => {
    dbRef.child(key).on('value', snap => callBack(snap.val()))
}

export const firebaseSet = (keys, value) => {
    let ref = dbRef;
    for (let i = 0; i < keys.length; i++) {
        ref=ref.child(keys[i]);
    }
    ref.set(value);
}

export const firebaseAdd = (key, value) => {
    const push = dbRef.child(key)
        .push();
    push.set(push.key, value);
}

