import React, {useState} from 'react';
import './Menu.css';

export const Menu = (props) => {
    const [show, setShow] = useState(false);

    const renderColorButton = (color) =>
        <button
            className={`button roundButton ${color}`}
            onClick={() => props.setColorScheme(color)}
        />

    return (
        <>
            {
                show && (
                    <div
                        className="touchOutsideSensor"
                        onClick={() => setShow(false)}
                    />
                )
            }
            <button
                className={`button menuButton${show ? ' open' : ''}`}
                onClick={() => setShow(!show)}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="66"
                    height="66"
                    viewBox="10 10 46 46"
                    fill="none"
                >
                    <line className="line1" x1="23.5" y1="23.75" x2="42.5" y2="23.75" strokeWidth="2.5" />
                    <line className="line2" x1="23.5" y1="31.75" x2="42.5" y2="31.75" strokeWidth="2.5" />
                    <line className="line3" x1="23.5" y1="39.75" x2="42.5" y2="39.75" strokeWidth="2.5" />
                </svg>
            </button>
            <div
                className={`menu popOut${show ? ' show' : ''}`}
                onClick={(e) =>
                    e.target.tagName === 'A' && setShow(false)
                }
            >
                {props.children}

                <div className="menu__colorButtons">
                    {renderColorButton('grey')}
                    {renderColorButton('blue')}
                </div>
            </div>
        </>
    );
}