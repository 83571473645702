import React from 'react';
import {Link} from "react-router-dom";
import {sorter} from "../utils";

export const Recipes = (props) => {

    const renderRecipe = (rec) => {
        return (
            <div
                className={'recipes__recipeWrapper'}
                key={rec.id}
            >
                <Link
                    className={'button listable recipes__recipe'}
                    to={`/${rec.id}`}
                >
                    {rec.name}
                </Link>
            </div>
        )
    }

    return (
        <div className="page">
            <h1 className={'header textCenter'}>Oppskrifter</h1>
            <div className="recipes__content flexcenter scrollSafe">
                {Object.values(props.recipes).sort(sorter).map(renderRecipe)}
            </div>
        </div>
    );
}